@charset "UTF-8";
/* ================================================================= 
 License : e-TRUST Inc.
 File name : common_style.css
 Style : common_style
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 2;
  letter-spacing: 0.05em;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 14px;
  word-wrap: break-word;
  font-weight: 400;
  background: url(../images/common/bodybg.png) center top;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.inner {
  margin: 0 auto;
  max-width: 960px;
}

@media screen and (max-width: 640px) {
  .inner {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.img100 img {
  width: 100% !important;
}

#contents_wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

.map li:last-child {
  display: none;
}

.map li a {
  text-decoration: none;
}

table td {
  margin-bottom: -1px !important;
  padding: 20px !important;
}

table td img {
  height: auto !important;
}

.pdnone td {
  padding: 0 !important;
}

.bornone td {
  border: none !important;
}

.pdtd10 td {
  padding: 10px !important;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a:hover {
  opacity: 0.6;
}

#contents_wrap {
  min-width: auto;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 640px) {
  #contents_wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#contents {
  max-width: 960px;
  width: 100%;
}

.imgR, .imgL {
  max-width: 30% !important;
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

header#global_header {
  max-width: 100%;
  min-width: 100% !important;
}

#main, #main #col_main {
  width: 100%;
}

footer#global_footer {
  min-width: 100% !important;
}

#contents_wrap {
  padding: 0 0 50px;
}

@media screen and (max-width: 834px) {
  #contents_wrap {
    padding: 0 10px 50px;
  }
}

@media screen and (max-width: 640px) {
  #contents_wrap {
    padding: 0 10px 50px;
  }
}

header#global_header {
  max-width: 100%;
}

#header {
  position: fixed;
  top: 0;
  width: 100%;
  width: 100%;
  padding: 20px 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

@media screen and (max-width: 834px) {
  #header {
    padding: 65px 0 20px;
    position: static;
    background: #1d2136;
  }
}

#header .inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  #header .inner {
    display: block;
  }
}

#nav_global ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
}

#nav_global ul li:last-child a:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 10px;
  content: "";
  display: block;
  margin: auto;
  background: #fff;
}

#nav_global ul li ul {
  position: absolute;
  top: 15px;
  left: 0;
  display: block;
  width: 100%;
  z-index: 999;
  padding-top: 40px;
}

#nav_global ul li ul li {
  display: block;
  width: 100%;
}

#nav_global ul li ul li a {
  display: block;
  font-size: 13px;
  background: #fff;
  text-align: center;
  text-decoration: none;
  color: #000;
  padding: 10px 0;
}

#nav_global ul li a {
  color: #fff;
  text-decoration: none;
  padding: 0 20px;
  position: relative;
}

#nav_global ul li a:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 10px;
  content: "";
  display: block;
  margin: auto;
  background: #fff;
}

.blog_header {
  position: static !important;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

#main_image {
  position: relative;
}

#main_image .copy {
  position: absolute;
  right: 3%;
  bottom: 3%;
  z-index: 10;
  width: 14%;
}

#main_image .copy img {
  width: auto;
  max-width: 100%;
}

#main_image img {
  width: 100%;
}

.mean-container .mean-bar {
  background: #1d2136 !important;
  position: fixed !important;
}

.mean-container .mean-nav {
  background: #1d2136 !important;
}

.mean-container .mean-nav ul li a.mean-expand {
  font-size: 22px !important;
  width: 15px !important;
  height: 12px !important;
}

#footer {
  background: url(../images/common/footer_bg.png) left top/contain no-repeat #1d2136;
  color: #fff;
  position: relative;
}

@media screen and (max-width: 834px) {
  #footer {
    background: #1d2136;
  }
}

#footer .pagetop {
  position: absolute;
  top: 0;
  right: 1%;
  bottom: 0;
  display: block;
  margin: auto;
  width: 45px;
  height: 45px;
}

@media screen and (max-width: 834px) {
  #footer .pagetop {
    position: static;
    padding-top: 30px;
  }
}

#footer .inner {
  text-align: right;
  padding: 50px 0;
}

@media screen and (max-width: 834px) {
  #footer .inner {
    text-align: center;
  }
}

#footer .inner .logo {
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

#footer .inner .nav a {
  display: inline-block;
  padding: 0 10px 10px;
}

#footer a {
  color: #fff;
  text-decoration: none !important;
}

#footer .copy {
  background: #fbf5e7;
  padding: 20px 0;
  color: #1d2136;
  font-weight: 900;
}

/* txt */
/* table */
.table_wrap1 .box1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
}

.table_wrap1 .box1:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 470px;
  height: 450px;
  content: '';
  background: url(../images/common/shioramen.png) center/cover no-repeat;
}

@media screen and (max-width: 834px) {
  .table_wrap1 .box1:before {
    right: -45%;
  }
}

.table_wrap1 .box1 div {
  width: 65px;
  margin-right: 30px !important;
}

.table_wrap1 h2 {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  color: #1d2136;
  font-size: 40px;
  font-weight: 900;
  border-right: 1px solid #bd0000;
  max-height: 130px;
  position: relative;
  z-index: 999999;
}

.table_wrap1 .box2 {
  line-height: 2;
  width: 70%;
}

@media screen and (max-width: 834px) {
  .table_wrap1 .box2 {
    background: rgba(255, 255, 255, 0.7);
    padding: 30px !important;
    position: relative;
    z-index: 999999;
  }
}

.table_wrap1 .box2 div {
  font-weight: bold;
}

.table_wrap1 .box2 div b {
  background: #eeeeee;
  color: #1d2136;
  display: inline-block;
  margin-top: 20px;
  padding: 10px;
}

.table_wrap2 .box1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.table_wrap2 h2 {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  color: #fff;
  font-size: 40px;
  font-weight: 900;
  border-right: 1px solid #bd0000;
  max-height: 170px;
  margin-right: 30px !important;
  position: relative;
  z-index: 999999;
  text-align: center !important;
}

@media screen and (max-width: 834px) {
  .table_wrap2 h2 {
    font-size: 12px;
    font-size: 3.75vw;
    max-height: 5em;
  }
}

.table_wrap3 .box1 {
  width: 40.5%;
}

.table_wrap3 .box2 {
  width: 30%;
}

.table_wrap3 .box3 {
  width: 30%;
}

.table_wrap3 .box3 img {
  width: 70% !important;
}

.table_wrap3 .box4 {
  width: 100%;
  display: block;
  margin-top: -38%;
}

.table_wrap4 table {
  max-width: 80%;
  margin: 0 auto;
}

.table_wrap5 .box1 {
  color: #1d2136;
  font-size: 18px;
}

.table_wrap5 .box1 div {
  font-weight: 900;
}

.table_wrap5 .box2 {
  font-size: 18px;
}

/* back */
.bg1 {
  background: url(../images/common/bg1.png) right top/cover no-repeat;
  padding: 100px 0;
}

.bg2 {
  background: url(../images/common/bg2.png) center top/cover no-repeat;
  padding: 100px 0;
}

.bg3 {
  background: url(../images/common/bg3.png) center left/cover no-repeat #fff;
  padding: 150px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 640px) {
  .bg3 {
    display: block;
    background: #fff;
    padding: 100px 0;
  }
}

/* title */
.h1txt {
  background: url(../images/common/h1bg.jpg) center top/cover no-repeat;
  padding: 180px 0 100px;
}

@media screen and (max-width: 834px) {
  .h1txt {
    padding: 80px 0;
  }
}

.h1txt article {
  max-width: 960px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .h1txt article {
    padding: 0 20px;
  }
}

.h1txt h1 {
  text-align: left !important;
  font-size: 35px;
  font-weight: bold;
  color: #1d2136;
}

.h2title h2 {
  border-bottom: 1px solid #1d2136;
  color: #1d2136;
  font-weight: bold;
  font-size: 20px;
}

/* common */
.idou {
  display: block;
  margin-bottom: 20px;
}

.idou h2 {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #bd0000;
  text-align: center;
}

.top_form {
  padding-right: 10%;
}

@media screen and (max-width: 640px) {
  .top_form {
    padding-right: 0;
  }
}

.top_form address {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.top_form p {
  text-align: center;
  margin-top: 30px;
}

.topbanner {
  position: relative;
  z-index: 999;
}

.news_wrap {
  align-items: stretch !important;
  position: relative;
}

@media screen and (max-width: 640px) {
  .news_wrap {
    display: block;
  }
}

.news_wrap .ramen {
  position: absolute;
  top: -40%;
  right: 0;
  width: 15%;
  z-index: 1;
}

@media screen and (max-width: 640px) {
  .news_wrap .ramen {
    display: none;
  }
}

.news_wrap .ramen img {
  max-width: 100% !important;
  width: 100%;
}

.news_wrap .box1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

@media screen and (max-width: 640px) {
  .news_wrap .box1 {
    width: 100%;
    align-items: stretch;
  }
}

.news_wrap .box1 .clearfix:after {
  display: none !important;
}

.news_wrap .box1 .news_title {
  padding: 50px 0;
  position: relative;
  height: 100%;
  width: 30%;
  background: url(../images/common/bg2.png) center top/cover no-repeat;
}

@media screen and (max-width: 640px) {
  .news_wrap .box1 .news_title {
    height: auto;
  }
}

.news_wrap .box1 .news_title article {
  display: block;
  height: 100%;
  position: relative;
}

.news_wrap .box1 .news_title h2 {
  text-align: center;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  color: #fff;
  font-size: 40px;
  font-weight: 900;
  border-right: 1px solid #bd0000;
  height: 200px;
  text-align: center !important;
  display: block;
  margin: auto;
  max-width: 70px;
}

@media screen and (max-width: 640px) {
  .news_wrap .box1 .news_title h2 {
    font-size: 30px;
    line-height: 1.2em;
    padding-right: 10px;
    max-width: auto;
  }
}

.news_wrap .box2_wrap {
  width: calc(100% / 2 - 1%);
  display: flex;
  align-items: stretch;
  z-index: 999;
}

@media screen and (max-width: 640px) {
  .news_wrap .box2_wrap {
    width: 100%;
  }
}

.news_wrap .box2 {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  max-width: 460px;
  margin: 50px 0;
  display: flex;
  align-items: stretch;
  background: #fff;
}

@media screen and (max-width: 640px) {
  .news_wrap .box2 {
    max-width: 100%;
  }
}

.news_wrap .box2 .box2_2 {
  border: 2px solid #ccc;
  padding: 20px;
}

.news_wrap .box2 h2 {
  font-size: 20px;
  font-weight: bold;
  color: #1d2136;
  border-bottom: 1px solid #ddd;
}

.border1 {
  border: 1px solid #ddd;
}

.flow_wrap dl dt {
  align-items: center !important;
  font-style: italic !important;
  font-weight: 900;
  font-size: 20px;
}

.menu_wrap dl {
  border-bottom: 1px dotted #999 !important;
}

.menu_wrap dl dt, .menu_wrap dl dd {
  background: none !important;
  border: none !important;
  font-weight: 900;
}

.menu_wrap dl dd {
  text-align: right !important;
}

.anccolor a {
  color: #1d2136;
}

.book_wrap {
  margin-top: 100px;
  border: 2px solid #eee;
  padding: 5px;
  background: #fff;
}

@media screen and (max-width: 640px) {
  .book_wrap {
    margin-top: 60px !important;
  }
}

.book_wrap img {
  max-width: 50% !important;
  width: 100% !important;
}

@media screen and (max-width: 640px) {
  .book_wrap img {
    max-width: 100% !important;
  }
}

.book_wrap h2 {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 900;
  font-size: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.book_wrap .box1 {
  border: 2px solid #ccc;
  padding: 30px;
}

@media screen and (max-width: 640px) {
  .book_wrap .box1 {
    padding: 10px;
  }
}

.book_wrap .box2 {
  margin-top: 30px;
}

@media screen and (max-width: 640px) {
  .ofjimg {
    margin-bottom: 3%;
  }
}

.ofjimg img {
  background: #fff;
  max-height: 225px !important;
  height: 100% !important;
  object-fit: contain !important;
}

.ofjimg2 img {
  object-fit: cover !important;
}

.imgbgcolor img {
  background: #f3f6ef;
}

.bordertext1 {
  border: 1px solid #ddd;
}

.pan1 a {
  display: inline-block;
  line-height: 30px;
  color: #1d2136;
  margin-top: 10px;
}

.disnone {
  display: none;
}

.bg100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.positioncenter {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

/* news */
.news {
  max-height: 360px;
  overflow-y: scroll;
  margin: 50px 0;
  width: 70%;
  padding-left: 5%;
}

.news dl {
  border-bottom: 1px dotted #999 !important;
  padding-bottom: 20px !important;
  margin-bottom: 20px !important;
}

@media screen and (max-width: 834px) {
  .news dl {
    display: block !important;
  }
}

.news dl dt, .news dl dd {
  border: none !important;
  padding: 0 !important;
  background: none !important;
}

@media screen and (max-width: 834px) {
  .news dl dt, .news dl dd {
    display: block;
    width: 100% !important;
  }
}

.news dl dt {
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .news dl dt {
    margin-bottom: 10px !important;
  }
}

div.blog_list {
  display: block !important;
  border: none !important;
}

div.blog_list div.blog_photo {
  display: none !important;
}

div.blog_list div.blog_text {
  width: 100% !important;
  font-size: 13px !important;
}

div.blog_list div.blog_text a {
  color: #1d2136;
  text-decoration: none;
}

/* flex */
.wrap1 {
  margin-left: -3.0303% !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .wrap1 {
    justify-content: space-between;
    margin-left: 0% !important;
  }
}

.wrap1 .box1 {
  width: 30.30303% !important;
  margin-left: 3.0303% !important;
}

@media screen and (max-width: 640px) {
  .wrap1 .box1 {
    width: calc(100% / 2 - 2%) !important;
    margin-left: 0 !important;
  }
}

.flex_wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.calc1 {
  width: calc(100% / 2 - 2%);
}

.calc2 {
  width: calc(100% / 3 - 2%);
}

.calc3 {
  width: calc(100% / 4 - 2%);
}

@media screen and (max-width: 834px) {
  .tabW100 {
    width: 100% !important;
  }
}

@media screen and (max-width: 834px) {
  .tabW50 {
    width: calc(100% / 2 - 2%) !important;
  }
}

@media screen and (max-width: 640px) {
  .spW100 {
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .spW50 {
    width: calc(100% / 2 - 2%) !important;
  }
}

/*================== プラポリ一体型お問い合わせSCSS =================*/
.policy {
  text-align: left;
}

.policy-text {
  font-size: 18px;
}

.policy h2 {
  border-left: 4px solid #1d2136;
  color: #333;
  padding-left: 8px;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: normal;
}

.policy-trriger {
  color: #1d2136;
  cursor: pointer;
  text-decoration: underline;
}

.policy-wrap {
  display: none;
  position: fixed;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 834px) {
  .policy-wrap {
    left: 0;
  }
}

.policy-wrap p {
  color: #333;
  line-height: 1.6;
}

.policy-wrap > div {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  -ms-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  opacity: 0;
  position: relative;
  padding: 20px;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #1d2136;
  height: 60vh;
  -ms-overflow-y: scroll;
  overflow-y: scroll;
}

@media screen and (max-width: 834px) {
  .policy-wrap > div {
    width: 95%;
  }
}

.policy-wrap > div a {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 2.4rem;
  font-weight: bold;
}

.policy {
  text-align: left;
}

.policy-text {
  font-size: 18px;
}

.policy-wrap h3 {
  margin-top: 16px;
  border-left: 4px solid #1d2136;
  color: #333;
  padding-left: 8px;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.policy p {
  margin: 10px 0 25px;
}

.policy-wrap.active {
  display: flex;
}

.policy-wrap.active > div {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
